<template>
    <div class="mt-3 stage-inner-container-padded">
        <div v-if="loading">
            <b-row v-if="!error">
                <b-col cols="12">
                    <h3>Processing... Hang In There, This Should Only Take A Few Moments</h3>
                </b-col>
            </b-row>
            <b-row v-else>
                <b-col cols="12">
                    <h3>Error</h3>
                </b-col>
                <b-col cols="12">
                    <h4>{{errorMessage}}</h4>
                </b-col>
            </b-row>
        </div>
        <div class="mb-3" v-else>
            <b-row>
                <b-col cols="12">
                    <h3>Create or Update Records by CSV - Step 2 - Field Assignment</h3>
                </b-col>
            </b-row>
            <hr>
            <b-row class="border-bottom pb-1 font-weight-bold">
                <b-col xl="1" md="2" sm="12" class="text-center">
                    Import?
                </b-col>
                <b-col xl="3" md="4" sm="12">
                    Column
                </b-col>
                <b-col xl="5" md="4" sm="12">
                    Desination
                </b-col>
                <b-col xl="3" md="2" sm="12">
                    Details
                </b-col>
            </b-row>
            <b-row v-for="(fField, index) in fileFields" :key="index" class="border-bottom my-0 py-1 font-weight-bold">
                <b-col xl="1" md="2" sm="12" >
                    <b-row class="text-center">
                        <b-col>
                            <b-form-checkbox
                                :id="`import-${fField}-${index}`"
                                v-model="assignments[fField].import"
                                :name="`import-${fField}-${index}`"
                                :value="true"
                                :unchecked-value="false"
                                @change="importChanged(fField)"
                            ></b-form-checkbox>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xl="3" md="4" sm="12">
                    <b-row>
                        <b-col class="font-weight-bold">
                            {{fField}}
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xl="5" md="4" sm="12">
                    <b-row>
                        <b-col>
                            <b-form-select size="sm" v-model="assignments[fField].assignment" :options="destinationOptionsNotAlreadySelected" @change="assignmentChanged(fField)" :state="(assignments[fField].error) ? false : null">
                                <template #first>
                                    <b-form-select-option :value="null">-- Skip This Column --</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xl="3" md="2" sm="12">
                    <b-row>
                        <b-col v-if="assignments[fField].error" class="font-weight-bold text-danger">
                            {{assignments[fField].errorMessage}}
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            <b-row class="mt-3">
                <b-col cols="12">
                    <b-button-toolbar justify>
                        <b-button variant="warning" @click="$router.go(-1)">Back</b-button>
                        <b-button variant="success" v-if="!processing" :disabled="!selectionValid" @click="applyFieldsToSessionAndContinue">Proceed To Next Step</b-button>
                        <b-button variant="success" v-else disabled><b-spinner label="Spinning"></b-spinner></b-button>
                    </b-button-toolbar>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
const butils = require('../../libs/basicUtils.js');
const _ = require('underscore');

export default {
    name: 'BulkInsertUpdateAssign',
    components:{
    },
    props:{
        sessionID: String
    },
    data(){
        return{
            loading: true,
            error: false,
            errorMessage: null,

            processing: false,

            // Actual Data
            sessionType: null,
            actsOnTable: null,
            rawTableFields: [],
            tableFields: [],
            fileFields: [],
            filePreview: [],

            requiredFields: [],
            
            // Field Assignments
            assignments: {},
        }
    },
    methods:{
        importChanged(fieldName){
            if(this.assignments[fieldName].import){
                // Importing, require the desination
                if(this.assignments[fieldName].assignment == null){
                    this.assignments[fieldName].error = true;
                    this.assignments[fieldName].errorMessage = "Error: Imported Columns Must Have A Desination";
                }
            }else{
                // No longer importing, clear the destination selection
                this.assignments[fieldName].error = false;
                this.assignments[fieldName].errorMessage = null;
            }
        },
        assignmentChanged(fieldName){
            if(this.assignments[fieldName].assignment == null){
                this.assignments[fieldName].import = false;
            }else{
                this.assignments[fieldName].import = true;
                this.assignments[fieldName].error = false;
                this.assignments[fieldName].errorMessage = null;
            }
        },
        applyFieldsToSessionAndContinue(){
            this.processing = true;
            // Pack up the Field Assignments
            var fields = _.keys(this.assignments);
            var activeAssignments = [];
            fields.forEach((ff)=>{
                if(this.assignments[ff].import == true && this.assignments[ff].assignment != null){
                    let tmp = {
                        fileField: ff,
                        assignment: this.assignments[ff].assignment
                    };
                    activeAssignments.push(tmp);
                }
            })
            var pack = {
                fieldAssignments: activeAssignments
            };
            butils.instance.post(process.env.VUE_APP_API_BASE_URL + `/bulk/session/assign/${this.sessionID}`, pack)
            .then((response)=>{
                // Move To Stage 3
                this.$router.push({path: `/home/upload/issues/${this.sessionID}`});
            })
            .catch((err)=>{
                this.error = true;
                if(butils.isError401(err)){
                    butils.createToast(this, 'Logged Out', 'Login Again', 'warning');
                    this.errorMessage = "Session Load Response 401 - Try Logging In Again";
                }else if(butils.isError403(err)){
                    butils.createToast(this, 'Request Failed Due To EPC Restrictions', 'Your user lacks the permissions required to make this request, please contact your administrator to receive permissions to perform this action.', 'danger');
                    this.errorMessage = "Session Load Response 403 - Insufficient Permissions";
                }else{
                    if(_.has(err, 'response')){
                        var resp = err.response;
                        this.errorMessage = resp.errors;
                    }else{
                        console.log(err);
                        this.errorMessage = "An Unexpected Error Occured Durring Session Field Assignment";
                        butils.createToast(this, this.errorMessage, 'Check the console for more info', 'danger');
                    }
                }
            })
            this.processing = false;
        }
    },
    watch:{

    },
    computed:{
        destinationOptionsNotAlreadySelected: function(){
            // Pulls the list of already selected table fields
            var alreadySelected = _.filter(_.map(this.assignments, (asn)=>{ return asn.assignment; }), (asnFilt)=>{ return asnFilt != null; });
            // Returns the list of table fields, with the already selected fields disabled from the selection.
            return _.each(this.tableFields, (tf)=>{ return tf.disabled = _.contains(alreadySelected, tf.value);});
        },
        selectionValid: function(){
            // This is valid WHEN: ALL OF THE FOLLOWING ARE TRUE
            // At least one fileField will be imported into a valid destination
            let assigned = _.filter(this.assignments, (asn)=>{ return asn.import && asn.assignment != null})
            if(assigned.length > 0){
                // All required table fields are filled
                var everyRequiredFieldFound = true;
                this.requiredFields.forEach((reqField)=>{
                    var foundThisReqField = false;
                    // Check if this is selected
                    this.fileFields.forEach((ff)=>{
                        if(reqField.id == this.assignments[ff].assignment){
                            foundThisReqField = true;
                        }
                    })
                    if(foundThisReqField == false){
                        everyRequiredFieldFound = false;
                    }
                })
                if(everyRequiredFieldFound){
                    return true;
                }else{
                    return false;
                }
            }else{
                // Doesnt Have Any Fields Selected
                return false;
            }
        }
    },
    beforeCreate(){

    },
    created(){

    },
    beforeMount (){

    },
    mounted(){
        butils.customInstance.timeoutLength(30000).get(process.env.VUE_APP_API_BASE_URL + `/bulk/session/assign/${this.sessionID}`)
        .then((response)=>{
            this.tableFields = [];
            this.assignments = {};
            this.requiredFields = [];
            var result = response.data.result;
            this.sessionType = result.type;
            this.rawTableFields = result.tableFields;
            
            this.rawTableFields.forEach((rtf)=>{
                // Collect the required fields
                if(this.sessionType == 'create' && rtf.required_for_create){
                    this.requiredFields.push(rtf);
                }else if(this.sessionType == 'update' && rtf.required_for_update){
                    this.requiredFields.push(rtf);
                }

                let tmp = {};
                if(this.sessionType == 'create' && !rtf.is_primary_key){
                    tmp = {
                        value: rtf.id,
                        text: `${rtf.name} ( ${rtf.type} )`,
                        disabled: false
                    };
                    this.tableFields.push(tmp);
                }else if(this.sessionType == 'update'){
                    tmp = {
                        value: rtf.id,
                        text: `${rtf.name} ( ${rtf.type} )`,
                        disabled: false
                    };
                    this.tableFields.push(tmp);
                }
                
            });
            this.actsOnTable = result.table;
            this.fileFields = result.fileFields;
            this.fileFields.forEach((ff)=>{
                
                let tmp = {
                    import: false,
                    assignment: null,
                    error: false,
                    errorMessage: null
                };
                this.$set(this.assignments, `${ff}`, tmp)
            })
            if(result.fieldAssignments != null && result.fieldAssignments.length > 0){
                result.fieldAssignments.forEach((fa)=>{
                    this.assignments[fa.fileField].import = true;
                    this.assignments[fa.fileField].assignment = fa.assignment;
                })
            }
            this.filePreview = result.filePreview;
            this.loading = false;
        })
        .catch((err)=>{
            this.error = true;
            if(butils.isError401(err)){
                butils.createToast(this, 'Logged Out', 'Login Again', 'warning');
                this.errorMessage = "Session Load Response 401 - Try Logging In Again";
            }else if(butils.isError403(err)){
                butils.createToast(this, 'Request Failed Due To EPC Restrictions', 'Your user lacks the permissions required to make this request, please contact your administrator to receive permissions to perform this action.', 'danger');
                this.errorMessage = "Session Load Response 403 - Insufficient Permissions";
            }else if(butils.isError404(err)){
                butils.createToast(this, 'Session Load Failed', 'The bulk import/update session you requested does not exist. It may have already been completed, was deleted by a user, or does not exist.', 'danger');
                this.errorMessage = "The bulk import/update session you requested does not exist. It may have already been completed, was deleted by a user, or does not exist.";
            }else{
                if(_.has(err, 'response')){
                    var resp = err.response;
                    this.errorMessage = resp.errors;
                }else{
                    console.log(err);
                    this.errorMessage = "An Unexpected Error Occured Durring Session Load";
                    butils.createToast(this, this.errorMessage, 'Check the console for more info', 'danger');
                }
            }
        })
    },
    beforeUpdate(){

    },
    updated(){

    },
    beforeDestroy(){

    },
    destroyed(){

    }
}
</script>

<style scoped>
    
</style>